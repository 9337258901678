import React from 'react';
import { Link } from 'gatsby';
import Main from '../components/page/Main';

const AllTags = ({ data, pageContext }) => {
  const { tags } = pageContext;
  return (
    <Main>
      <div>
        <ul>
          {tags.map((tagName, index) => {
            return (
              <li key={index}>
                <Link to={`/tags/${tagName}`}>{tagName}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </Main>
  );
};

export default AllTags;
